import React from "react";
import { Layout } from 'antd';

const { Footer } = Layout;

export const FooterPage = () => {
  return (
    <Footer>
      <div>Family game. ©2025</div>
    </Footer>
  );
};
